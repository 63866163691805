body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

/* pdfheadercss */
#responsivepdfheader {
  width:95%;
  margin:auto;
  padding:30px 0px;
}

@media screen and (max-width: 600px) {
  #responsivepdfheader {
    padding: 30px 10px;
  }
  #responsivepdfheader b,
  span {
    font-size: 8px !important;
    line-height: 10px !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 12px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C1C1C1; 
  border-radius: 10px;
}

#footerScroll {
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 16px;
  background-color: #1976d2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  transition: transform 0.3s ease;
  padding: 0px 7px;
  flex-wrap: wrap;
  font-size: 12px;
}

/* Media query for small devices (up to 600px wide) */
@media screen and (max-width: 600px) {
  #footerScroll {
    flex-direction: column;
    height: auto;
    justify-content: center;
    bottom: -3px;
  }
  #footerScroll span {
    margin-bottom: 4px;
    font-size: 1.2rem;
  }
}
